import React, { useRef, useEffect, useState } from "react";
import "./styles.css";

const ShapePattern = () => {
  const [layoutType, setLayoutType] = useState("rings");
  const [rings, setRings] = useState(7);
  const [columns, setColumns] = useState(10);
  const [rows, setRows] = useState(10);
  const [seed, setSeed] = useState(7);
  const [gridSize, setGridSize] = useState(7);
  const [shapeScale, setShapeScale] = useState(0.8);
  const [padding, setPadding] = useState(45);
  const [startColor, setStartColor] = useState("#4a4a4a");
  const [endColor, setEndColor] = useState("#f0f0f0");
  const [bgStartColor, setBgStartColor] = useState("#ffffff");
  const [bgEndColor, setBgEndColor] = useState("#ffffff");
  const [shapeType, setShapeType] = useState("circle");
  const [customText, setCustomText] = useState("A");
  const [animate, setAnimate] = useState(false);
  const [animationType, setAnimationType] = useState("rotation");
  const [animationProgress, setAnimationProgress] = useState(0);
  const [animationSpeed, setAnimationSpeed] = useState(0.05);
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const svgRef = useRef(null);

  const canvasSizes = [
    { name: "Instagram Post", width: 1080, height: 1080 },
    { name: "Instagram Story", width: 1080, height: 1920 },
    { name: "Facebook Post", width: 1200, height: 630 },
    { name: "Twitter Post", width: 1200, height: 675 },
    { name: "HD", width: 1920, height: 1080 },
    { name: "4K", width: 3840, height: 2160 },
  ];

  const interpolateColor = (color1, color2, factor) => {
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);
    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);
    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));
    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  const generateShape = (x, y, size, color, index) => {
    let animatedProps = {};

    if (animate) {
      switch (animationType) {
        case "pulse":
          const scaleFactor =
            1 + Math.sin(animationProgress + index * 0.1) * 0.2;
          size *= scaleFactor;
          break;
        case "wave":
          x += Math.sin(animationProgress + index * 0.1) * 10;
          y += Math.cos(animationProgress + index * 0.1) * 10;
          break;
        case "colorShift":
          color = interpolateColor(
            startColor,
            endColor,
            (Math.sin(animationProgress + index * 0.1) + 1) / 2
          );
          break;
        default:
          break;
      }
    }

    switch (shapeType) {
      case "circle":
        return (
          <circle cx={x} cy={y} r={size / 2} fill={color} {...animatedProps} />
        );
      case "triangle":
        const height = (size * Math.sqrt(3)) / 2;
        return (
          <polygon
            points={`${x},${y - height / 2} ${x - size / 2},${y + height / 2} ${
              x + size / 2
            },${y + height / 2}`}
            fill={color}
            {...animatedProps}
          />
        );
      case "roundedRect":
        return (
          <rect
            x={x - size / 2}
            y={y - size / 2}
            width={size}
            height={size}
            rx={size / 4}
            ry={size / 4}
            fill={color}
            {...animatedProps}
          />
        );
      case "text":
        return (
          <text
            x={x}
            y={y}
            fontSize={size}
            fill={color}
            textAnchor="middle"
            dominantBaseline="central"
            {...animatedProps}
          >
            {customText}
          </text>
        );
      default:
        return null;
    }
  };

  const generateRingPattern = () => {
    let shapes = [];
    const svgSize = Math.min(canvasSize.width, canvasSize.height);
    const centerX = canvasSize.width / 2;
    const centerY = canvasSize.height / 2;
    const maxRadius = ((rings - 1) * gridSize) / 2;

    for (let i = 0; i < rings; i++) {
      const numShapes = seed * (i + 1);
      const circumference = (numShapes * 2 * gridSize) / 2;
      const radius = circumference / (2 * Math.PI);
      const angleIncrement = (2 * Math.PI) / numShapes;

      for (let j = 0; j < numShapes; j++) {
        const angle = j * angleIncrement;
        const x = radius * Math.cos(angle) + centerX;
        const y = radius * Math.sin(angle) + centerY;
        const distanceFromCenter = Math.sqrt(
          (x - centerX) ** 2 + (y - centerY) ** 2
        );
        const colorFactor = distanceFromCenter / maxRadius;
        const color = interpolateColor(startColor, endColor, colorFactor);
        const index = i * numShapes + j;

        shapes.push(
          <React.Fragment key={`${i}-${j}`}>
            {generateShape(x, y, gridSize * shapeScale, color, index)}
          </React.Fragment>
        );
      }
    }

    shapes.push(
      <React.Fragment key="center">
        {generateShape(
          centerX,
          centerY,
          gridSize * shapeScale,
          startColor,
          shapes.length
        )}
      </React.Fragment>
    );

    return shapes;
  };

  const generateGridPattern = () => {
    let shapes = [];
    const startX = padding;
    const startY = padding;
    const svgWidth = canvasSize.width - padding * 2;
    const svgHeight = canvasSize.height - padding * 2;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        const x = startX + j * (svgWidth / (columns - 1));
        const y = startY + i * (svgHeight / (rows - 1));
        const distanceFromCenter = Math.sqrt(
          (x - canvasSize.width / 2) ** 2 + (y - canvasSize.height / 2) ** 2
        );
        const maxDistance = Math.sqrt(
          Math.pow(canvasSize.width / 2, 2) + Math.pow(canvasSize.height / 2, 2)
        );
        const colorFactor = distanceFromCenter / maxDistance;
        const color = interpolateColor(startColor, endColor, colorFactor);
        const index = i * columns + j;

        shapes.push(
          <React.Fragment key={`${i}-${j}`}>
            {generateShape(x, y, gridSize * shapeScale, color, index)}
          </React.Fragment>
        );
      }
    }

    return shapes;
  };

  const generateShapes = () => {
    return layoutType === "rings"
      ? generateRingPattern()
      : generateGridPattern();
  };

  useEffect(() => {
    let animationFrameId;
    if (animate) {
      const animate = () => {
        setAnimationProgress((prev) => (prev + animationSpeed) % (Math.PI * 2));
        animationFrameId = requestAnimationFrame(animate);
      };
      animationFrameId = requestAnimationFrame(animate);
    }
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [animate, animationSpeed]);

  const exportSVG = () => {
    const svgData = new XMLSerializer().serializeToString(svgRef.current);
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "shape_pattern.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const exportPNG = () => {
    const canvas = document.createElement("canvas");
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    const svgData = new XMLSerializer().serializeToString(svgRef.current);
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "shape_pattern.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const handleSliderWheel = (e, setValue, min, max, step) => {
    e.preventDefault();
    setValue((prevValue) => {
      const newValue = prevValue - Math.sign(e.deltaY) * step;
      return Math.max(min, Math.min(max, newValue));
    });
  };

  return (
    <div className="shape-pattern-container">
      <div className="shape-pattern-view">
        <h1 className="title">Shape Pattern Generator</h1>
        <div className="svg-container">
          <svg
            ref={svgRef}
            width={canvasSize.width}
            height={canvasSize.height}
            viewBox={`0 0 ${canvasSize.width} ${canvasSize.height}`}
            preserveAspectRatio="xMidYMid meet"
          >
            <defs>
              <linearGradient
                id="bgGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: bgStartColor, stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: bgEndColor, stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <rect width="100%" height="100%" fill="url(#bgGradient)" />
            <g
              transform={
                animationType === "rotation"
                  ? `rotate(${animationProgress * (180 / Math.PI)} ${
                      canvasSize.width / 2
                    } ${canvasSize.height / 2})`
                  : ""
              }
            >
              {generateShapes()}
            </g>
          </svg>
        </div>
      </div>
      <div className="shape-pattern-controls">
        <div className="control-scroll">
          <div className="control-group">
            <label className="control-label">Canvas Size</label>
            <select
              className="select"
              value={`${canvasSize.width}x${canvasSize.height}`}
              onChange={(e) => {
                const [width, height] = e.target.value.split("x").map(Number);
                setCanvasSize({ width, height });
              }}
            >
              {canvasSizes.map((size) => (
                <option key={size.name} value={`${size.width}x${size.height}`}>
                  {size.name} ({size.width}x{size.height})
                </option>
              ))}
            </select>
          </div>
          <div className="control-group">
            <label className="control-label">Layout Type</label>
            <select
              className="select"
              value={layoutType}
              onChange={(e) => setLayoutType(e.target.value)}
            >
              <option value="rings">Rings</option>
              <option value="grid">Grid</option>
            </select>
          </div>
          <div className="control-group">
            <label className="control-label">Shape Type</label>
            <select
              className="select"
              value={shapeType}
              onChange={(e) => setShapeType(e.target.value)}
            >
              <option value="circle">Circle</option>
              <option value="triangle">Triangle</option>
              <option value="roundedRect">Rounded Rectangle</option>
              <option value="text">Text</option>
            </select>
          </div>
          {shapeType === "text" && (
            <div className="control-group">
              <label className="control-label">Custom Text</label>
              <input
                type="text"
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
                className="text-input"
              />
            </div>
          )}
          {layoutType === "rings" ? (
            <div className="control-group">
              <label className="control-label">Rings: {rings}</label>
              <input
                type="range"
                min={1}
                max={30}
                value={rings}
                onChange={(e) => setRings(Number(e.target.value))}
                onWheel={(e) => handleSliderWheel(e, setRings, 1, 30, 1)}
                className="slider"
              />
            </div>
          ) : (
            <>
              <div className="control-group">
                <label className="control-label">Columns: {columns}</label>
                <input
                  type="range"
                  min={1}
                  max={30}
                  value={columns}
                  onChange={(e) => setColumns(Number(e.target.value))}
                  onWheel={(e) => handleSliderWheel(e, setColumns, 1, 30, 1)}
                  className="slider"
                />
              </div>
              <div className="control-group">
                <label className="control-label">Rows: {rows}</label>
                <input
                  type="range"
                  min={1}
                  max={30}
                  value={rows}
                  onChange={(e) => setRows(Number(e.target.value))}
                  onWheel={(e) => handleSliderWheel(e, setRows, 1, 30, 1)}
                  className="slider"
                />
              </div>
            </>
          )}
          <div className="control-group">
            <label className="control-label">Seed: {seed.toFixed(1)}</label>
            <input
              type="range"
              min={1}
              max={20}
              step={0.1}
              value={seed}
              onChange={(e) => setSeed(Number(e.target.value))}
              onWheel={(e) => handleSliderWheel(e, setSeed, 1, 20, 0.1)}
              className="slider"
            />
          </div>
          <div className="control-group">
            <label className="control-label">
              Grid Size: {gridSize.toFixed(1)}
            </label>
            <input
              type="range"
              min={5}
              max={30}
              step={0.1}
              value={gridSize}
              onChange={(e) => setGridSize(Number(e.target.value))}
              onWheel={(e) => handleSliderWheel(e, setGridSize, 5, 30, 0.1)}
              className="slider"
            />
          </div>
          <div className="control-group">
            <label className="control-label">
              Shape Scale: {shapeScale.toFixed(1)}
            </label>
            <input
              type="range"
              min={0.1}
              max={2}
              step={0.1}
              value={shapeScale}
              onChange={(e) => setShapeScale(Number(e.target.value))}
              onWheel={(e) => handleSliderWheel(e, setShapeScale, 0.1, 2, 0.1)}
              className="slider"
            />
          </div>
          <div className="control-group">
            <label className="control-label">
              Padding: {padding.toFixed(1)}
            </label>
            <input
              type="range"
              min={0}
              max={50}
              step={0.1}
              value={padding}
              onChange={(e) => setPadding(Number(e.target.value))}
              onWheel={(e) => handleSliderWheel(e, setPadding, 0, 50, 0.1)}
              className="slider"
            />
          </div>
          <div className="color-controls">
            <div className="color-control">
              <label className="control-label">Start Color</label>
              <input
                type="color"
                value={startColor}
                onChange={(e) => setStartColor(e.target.value)}
                className="color-input"
              />
            </div>
            <div className="color-control">
              <label className="control-label">End Color</label>
              <input
                type="color"
                value={endColor}
                onChange={(e) => setEndColor(e.target.value)}
                className="color-input"
              />
            </div>
          </div>
          <div className="color-controls">
            <div className="color-control">
              <label className="control-label">BG Start Color</label>
              <input
                type="color"
                value={bgStartColor}
                onChange={(e) => setBgStartColor(e.target.value)}
                className="color-input"
              />
            </div>
            <div className="color-control">
              <label className="control-label">BG End Color</label>
              <input
                type="color"
                value={bgEndColor}
                onChange={(e) => setBgEndColor(e.target.value)}
                className="color-input"
              />
            </div>
          </div>
          <div className="control-group">
            <div className="switch-container">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={animate}
                  onChange={(e) => setAnimate(e.target.checked)}
                />
                <span className="slider-switch"></span>
              </label>
              <span className="switch-label">Animate</span>
            </div>
          </div>
          {animate && (
            <>
              <div className="control-group">
                <label className="control-label">Animation Type</label>
                <select
                  className="select"
                  value={animationType}
                  onChange={(e) => setAnimationType(e.target.value)}
                >
                  <option value="rotation">Rotation</option>
                  <option value="pulse">Pulse</option>
                  <option value="wave">Wave</option>
                  <option value="colorShift">Color Shift</option>
                </select>
              </div>
              <div className="control-group">
                <label className="control-label">
                  Animation Speed: {animationSpeed.toFixed(2)}
                </label>
                <input
                  type="range"
                  min={0.01}
                  max={0.2}
                  step={0.01}
                  value={animationSpeed}
                  onChange={(e) => setAnimationSpeed(Number(e.target.value))}
                  onWheel={(e) =>
                    handleSliderWheel(e, setAnimationSpeed, 0.01, 0.2, 0.01)
                  }
                  className="slider"
                />
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <button className="button" onClick={exportSVG}>
            Export SVG
          </button>
          <button className="button" onClick={exportPNG}>
            Export PNG
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShapePattern;
